import React, { useContext, useState, useEffect } from "react"
import { Page, Layout, LegacyCard, Icon, LegacyStack, Text, Box, InlineGrid, Card, Button, Banner } from "@shopify/polaris";
import styled from 'styled-components'
import { doc, collection, orderBy, query } from 'firebase/firestore';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import FirebaseContext from '../../../providers/firebase';
import { CreditCardIcon, CheckIcon, CheckCircleIcon } from "@shopify/polaris-icons";
import formatCurrency from '../../../helpers/formatCurrency';
import CardDivider from "../../../components/cardDivider";
import { navigate } from "gatsby"
const BillingWrapper = styled.div`
    .plan-details {
        .plan-name: {
            margin-bottom: 20px;
        }
    }
    .plan-note {
        padding: 20px 0;
        span {
            font-size: 10px;
            color: #8C9196;
        }
    }
    .all-plans-include {
        list-style: none;
        padding-left: 0;
        li {
            padding-bottom:5px;
        }
    }
    .metrics {
        margin-top: 10px;
        .Polaris-DescriptionList__Term{
            flex: 0 1 30%;
        }
        .Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term {
            border-top: none;
        }
        .Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term + .Polaris-DescriptionList__Description {
            border-top: none;
        }
        .Polaris-DescriptionList--spacingTight .Polaris-DescriptionList__Term {
            padding: 0.4rem 0.8rem 0.4rem 0;
        }
        .Polaris-DescriptionList--spacingTight .Polaris-DescriptionList__Description {
            padding: 0.4rem 0;
        }
    }
`
function numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function revenueToPlan(revenue) {
    let plan
    switch (true) {
        case (revenue < 751):
            plan = "Launch Plan - $24.99/mo"
            break;
        case (revenue < 3001):
            plan = "Pro Plan - $99.99/mo"
            break;
        case (revenue < 10001):
            plan = "Advanced Plan - $199.99/mo"
            break;
        default:
            plan = "Unlimited Plan - $249.99/mo"
            break;
    }
    return plan
}
function planToPlanPrice(usageCharge, shopData) {
    let plan = currentPlan(usageCharge, shopData)
    let planPrice = "Launch Plan"
    switch (plan) {
        case "Launch Plan":
          planPrice = "Launch Plan - $24.99/mo";
          break;
        case "Pro Plan":
          planPrice = "Pro Plan - $99.99/mo";
          break;
        case "Advanced Plan":
          planPrice = "Advanced Plan - $199.99/mo";
          break;
        case "Unlimited Plan":
          planPrice = "Unlimited Plan - $249.99/mo";
      }
    return planPrice
}
function comparePlans(plan){
    let value = 0
    switch (plan) {
      case "Pro Plan":
        value = 1;
        break;
      case "Advanced Plan":
        value = 2;
        break;
      case "Scale 1 Plan":
        value = 3;
        break;
      case "Scale 2 Plan":
        value = 4;
        break;
      case "Scale 3 Plan":
        value = 5;
        break;
      case "Scale 4 Plan":
        value = 6;
        break;
      case "Unlimited Plan":
        value = 7;
        break;
    }
    return value
  }
function currentPlan(usageCharge,shopData){
    let planDesp = shopData && shopData.subscriptionPlan ? shopData.subscriptionPlan:"Launch Plan"
    if(usageCharge){
        usageCharge.forEach(charge => {
          if(charge.id){
            if(shopData && shopData.subscriptionPlan){
                if(shopData.subscriptionPlan === planDesp || comparePlans(charge.description) > comparePlans(planDesp)){
                    
                    planDesp = charge.description
                }
            }else{
                if("Launch Plan" === planDesp || comparePlans(charge.description) > comparePlans(planDesp)){
                    planDesp = charge.description
                }
            }
          }
        })
    }
    return planDesp
}
function newRevenueToPlan(revenue) {
    let plan
    switch (true) {
        case (revenue < 501):
            plan = "Launch Plan - $39.00/mo"
            break;
        case (revenue < 3001):
            plan = "Pro Plan - $119.00/mo"
            break;
        case (revenue < 10001):
            plan = "Advanced Plan - $199.00/mo"
            break;
        default:
            plan = "Unlimited Plan - $279.00/mo"
            break;
    }
    return plan
}
function newPlanToPlanPrice(usageCharge, shopData) {
    let plan = currentPlan(usageCharge, shopData)
    let planPrice = "Launch Plan"
    switch (plan) {
        case "Launch Plan":
          planPrice = "Launch Plan - $39.00/mo";
          break;
        case "Pro Plan":
          planPrice = "Pro Plan - $119.00/mo";
          break;
        case "Advanced Plan":
          planPrice = "Advanced Plan - $199.00/mo";
          break;
        case "Unlimited Plan":
          planPrice = "Unlimited Plan - $279.00/mo";
      }
    return planPrice
}
function newRevenueToPlan1(revenue) {
    let plan
    switch (true) {
        case (revenue < 501):
            plan = "Launch Plan - $49.00/mo"
            break;
        case (revenue < 3001):
            plan = "Pro Plan - $119.00/mo"
            break;
        case (revenue < 10001):
            plan = "Advanced Plan - $199.00/mo"
            break;
        default:
            plan = "Unlimited Plan - $299.00/mo"
            break;
    }
    return plan
}
function newPlanToPlanPrice1(usageCharge, shopData) {
    let plan = currentPlan(usageCharge, shopData)
    let planPrice = "Launch Plan"
    switch (plan) {
        case "Launch Plan":
          planPrice = "Launch Plan - $49.00/mo";
          break;
        case "Pro Plan":
          planPrice = "Pro Plan - $119.00/mo";
          break;
        case "Advanced Plan":
          planPrice = "Advanced Plan - $199.00/mo";
          break;
        case "Unlimited Plan":
          planPrice = "Unlimited Plan - $299.00/mo";
      }
    return planPrice
}
function newPlanToPlanPrice2(usageCharge, shopData) {
    let plan = currentPlan(usageCharge, shopData)
    let planPrice = "Launch Plan"
    switch (plan) {
        case "Launch Plan":
          planPrice = "Launch Plan - $49.00/mo";
          break;
        case "Pro Plan":
          planPrice = "Pro Plan - $119.00/mo";
          break;
        case "Advanced Plan":
          planPrice = "Advanced Plan - $199.00/mo";
          break;
        case "Scale 1 Plan":
          planPrice = "Scale 1 Plan - $299.00/mo";
          break;
        case "Scale 2 Plan":
          planPrice = "Scale 2 Plan - $399.00/mo";
          break;
        case "Scale 3 Plan":
          planPrice = "Scale 3 Plan - $499.00/mo";
          break;
        case "Scale 4 Plan":
          planPrice = "Scale 4 Plan - $699.00/mo";
          break;  
        case "Unlimited Plan":
          planPrice = "Unlimited Plan - $799.00/mo";
      }
    return planPrice
}
function Billing(props) {
  const { firebase, shop, token } = useContext(FirebaseContext);
  const revenueDocs = query(collection(firebase.firestore, 'shops', shop, 'revenues'), orderBy("createdAt", "desc"));
  const [revenues, revenuesLoading, revenuesError] = useCollection(
    revenueDocs
  );
  const shopDoc = doc(firebase.firestore, 'shops', shop);
  const [shopData, shopDataLoading, shopDataError] = useDocumentData(
    shopDoc
  );
  const date = new Date('2022-03-30')
  const sDate = new Date('2022-05-11')
  const oDate = new Date('2023-11-14')
  const [plan, setPlan] = useState("new")
  const locale = shopData && shopData.shopData && shopData.shopData.primary_locale || "en-US";
  const currency = shopData && shopData.shopData && shopData.shopData.currency || "USD";

  useEffect(() => {
    if(shopData ){
        const appInstalledTime = new Date(shopData.createdAt.seconds * 1000)
        if(appInstalledTime < date ){
            setPlan("old")
        }else{
            if(appInstalledTime < sDate ){
                setPlan("old1")
            }else{
                if(appInstalledTime < oDate ){
                    setPlan("old2")
                }
            }
        }
    }
  }, [shopData])
  

        return (
            <Page backAction={{
                content: 'Settings',
                onAction: () => {
                    navigate("/app/settings")
                }}} 
                title="Billing">
                <Layout>
                <Layout.Section>
                        <LegacyCard title="Your metrics">
                            <CardDivider/>
                            <Box padding="400">
                            {revenues && revenues.size > 0 ?
                            <LegacyStack>
                                <div>
                                <Text variant="headingSm" as="h3">Your current plan</Text>
                                <Text variant="bodyMd" as="p">
                                    {
                                      "old" === plan ? 
                                         planToPlanPrice(revenues.docs[0].data().usageCharge,shopData)
                                         :
                                            "old1" === plan? 
                                            newPlanToPlanPrice(revenues.docs[0].data().usageCharge,shopData)
                                            :
                                            "old2" === plan? 
                                            newPlanToPlanPrice1(revenues.docs[0].data().usageCharge,shopData)
                                            :
                                            newPlanToPlanPrice2(revenues.docs[0].data().usageCharge,shopData)  
                                    }
                                </Text>
                                </div>
                                <div>
                                <Text variant="headingSm" as="h3">All time upsell revenue</Text>
                                <Text variant="bodyMd" as="p">
                                {formatCurrency(revenues.docs.reduce((memo, d) => memo + d.data().revenue, 0), locale, 'USD')}
                                </Text>
                                </div>
                                </LegacyStack>
                                :
                                <LegacyStack>
                                    <div>
                                <Text variant="headingSm" as="h3">Upsell revenue this billing period</Text>
                                <Text variant="bodyMd" as="p">
                                $0
                                </Text>
                                </div>
                                <div>
                                <Text variant="headingSm" as="h3">Current plan</Text>
                                <Text variant="bodyMd" as="p">
                                {"old" === plan ? 'Launch Plan - $24.99/mo':"old1" === plan? 'Launch Plan - $39.00/mo': shopData && "Launch Plan" === shopData.subscriptionPlan ? 'Launch Plan - $49.00/mo':'Pro Plan - $119.00/mo'}
                                </Text>
                                </div>
                                <div>
                                <Text variant="headingSm" as="h3">All time upsell revenue</Text>
                                <Text variant="bodyMd" as="p">
                                $0
                                </Text>
                                </div>
                                </LegacyStack>    
                            }
                            </Box>
                        </LegacyCard>
                    </Layout.Section>
                    
                    <Layout.Section>
                    <Box paddingBlockEnd="400">
                        <Box paddingBlockEnd="400">
                        <Text  variant="headingMd" as="h2">Billing & usage charges</Text>
                        </Box>
                            <InlineGrid gap="200" columns={{xs: 1, sm: 2, md: 4, lg: 4, xl: 4}}>
                            { shopData && "Launch Plan" === shopData.subscriptionPlan ?
                                    <Card>
                                           <Box minHeight="76px">
                                            <div className="plan-details">
                                                <div className="plan-name">
                                                <Text  variant="bodyMd" as="span" fontWeight="semibold">Launch Plan</Text>
                                                </div>
                                                <LegacyStack spacing="extraTight" alignment="center">
                                                    
                                                    <Text variant="headingMd" as="h2">{"old" === plan ? "$24.99":"old1" === plan? "$39.00":"$49.00"}</Text>
                                                    <span> /mon</span>
                                                </LegacyStack>
                                            </div>
                                            { "Launch Plan" === currentPlan(revenues && revenues.size> 0 ? revenues.docs[0].data().usageCharge : null, shopData) &&
                                            <Box paddingBlockStart="200">
                                                <Button disabled>Active plan</Button>
                                            </Box>
                                            }
                                            </Box>
                                        <CardDivider/>
                                        <Box paddingBlockStart="200">
                                            <Text  variant="bodyMd" as="span" fontWeight="semibold">FEATURES</Text>
                                            <ul style={{listStyle:"none", paddingLeft:0}}>
                                                <li>
                                                    <LegacyStack spacing="extraTight">
                                                            <Icon
                                                                source={CheckCircleIcon}
                                                                tone="success" />
                                                        <Text variant="bodyMd" as="span">Earn up to </Text>
                                                        <Text variant="headingSm" as="h3">{"old" === plan ? "$750":"$500" }</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Design customization</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">In-depth analytics</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight" wrap={false}>
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Advanced configurator with preview</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Email support</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Smart rule engine</Text>
                                                    </LegacyStack>
                                                </li>
                                        </ul>
                                        </Box>
                                    </Card>
                                    :
                                    false
                                    }
                                    <Card>
                                        <Box minHeight="76px">
                                            <div className="plan-details">
                                                <div className="plan-name">
                                                <Text  variant="bodyMd" as="span" fontWeight="semibold">Pro Plan</Text>
                                                </div>
                                                <LegacyStack spacing="extraTight" alignment="center">
                                                    <Text variant="headingMd" as="h2">{"old" === plan ? "$99.99":"$119.00"}</Text>
                                                    <span> /mon</span>
                                                </LegacyStack>
                                            </div>
                                            { "Pro Plan" === currentPlan(revenues && revenues.size> 0 ? revenues.docs[0].data().usageCharge : null, shopData) &&
                                            <Box paddingBlockStart="200">
                                                <Button disabled>Active plan</Button>
                                            </Box>
                                            }
                                        </Box>
                                        <CardDivider/>
                                        <Box paddingBlockStart="200">
                                        <Text  variant="bodyMd" as="span" fontWeight="semibold">FEATURES</Text>
                                            <ul style={{listStyle:"none", paddingLeft:0}}>
                                                <li>
                                                    <LegacyStack spacing="extraTight">
                                                            <Icon
                                                                source={CheckCircleIcon}
                                                                tone="success" />
                                                        <Text variant="bodyMd" as="span">Earn up to </Text>
                                                        <Text variant="headingSm" as="h3">$2,000</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Design customization</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">In-depth analytics</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight" wrap={false}>
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Advanced configurator with preview</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Email support</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Smart rule engine</Text>
                                                    </LegacyStack>
                                                </li>
                                        </ul>
                                        </Box>
                                    </Card>
                                    <Card>
                                        <Box minHeight="76px">
                                            <div className="plan-details">
                                                <div className="plan-name">
                                                <Text  variant="bodyMd" as="span" fontWeight="semibold">Advanced Plan</Text>
                                                </div>
                                                <LegacyStack spacing="extraTight" alignment="center">
                                                    
                                                    <Text variant="headingMd" as="h2">{"old" === plan ? "$199.99":"$199.00"}</Text>
                                                    <span> /mon</span>
                                                </LegacyStack>
                                            </div>
                                            { "Advanced Plan" === currentPlan(revenues && revenues.size> 0 ? revenues.docs[0].data().usageCharge : null, shopData) &&
                                            <Box paddingBlockStart="200">
                                                <Button disabled>Active plan</Button>
                                            </Box>
                                            }
                                        </Box>
                                        <CardDivider/>
                                        <Box paddingBlockStart="200">
                                        <Text  variant="bodyMd" as="span" fontWeight="semibold">FEATURES</Text>

                                            <ul style={{listStyle:"none", paddingLeft:0}}>
                                                <li>
                                                    <LegacyStack spacing="extraTight">
                                                            <Icon
                                                                source={CheckCircleIcon}
                                                                tone="success" />
                                                        <Text variant="bodyMd" as="span">Earn up to </Text>
                                                        <Text variant="headingSm" as="h3">$5,000</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Design customization</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">In-depth analytics</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight" wrap={false}>
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Advanced configurator with preview</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Email support</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Smart rule engine</Text>
                                                    </LegacyStack>
                                                </li>
                                        </ul>
                                        </Box>
                                    </Card>
                                    { "new" === plan && 
                                     <Card>
                                        <Box minHeight="76px">
                                            <div className="plan-details">
                                                <div className="plan-name">
                                                <Text  variant="bodyMd" as="span" fontWeight="semibold">Scale 1 Plan</Text>
                                                </div>
                                                <LegacyStack spacing="extraTight" alignment="center">
                                                   
                                                    <Text variant="headingMd" as="h2">$299.00</Text>
                                                    <span> /mon</span>
                                                </LegacyStack>
                                            </div>
                                            { "Scale 1 Plan" === currentPlan(revenues && revenues.size> 0 ? revenues.docs[0].data().usageCharge : null, shopData) &&
                                            <Box paddingBlockStart="200">
                                                <Button disabled>Active plan</Button>
                                            </Box>
                                            }
                                        </Box>
                                        <CardDivider/>
                                        <Box paddingBlockStart="200">
                                        <Text  variant="bodyMd" as="span" fontWeight="semibold">FEATURES</Text>

                                            <ul style={{listStyle:"none", paddingLeft:0}}>
                                                <li>
                                                    <LegacyStack spacing="extraTight">
                                                            <Icon
                                                                source={CheckCircleIcon}
                                                                tone="success" />
                                                        <Text variant="bodyMd" as="span">Earn up to </Text>
                                                        <Text variant="headingSm" as="h3">$10,000</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Design customization</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">In-depth analytics</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight" wrap={false}>
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Advanced configurator with preview</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Email support</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Smart rule engine</Text>
                                                    </LegacyStack>
                                                </li>
                                        </ul>
                                        </Box>
                                    </Card>
                                    }
                                    { "new" === plan && 
                                     <Card>
                                        <Box minHeight="76px">
                                            <div className="plan-details">
                                                <div className="plan-name">
                                                <Text  variant="bodyMd" as="span" fontWeight="semibold">Scale 2 Plan</Text>
                                                </div>
                                                <LegacyStack spacing="extraTight" alignment="center">
                                                   
                                                    <Text variant="headingMd" as="h2">$399.00</Text>
                                                    <span> /mon</span>
                                                </LegacyStack>
                                            </div>
                                            { "Scale 2 Plan" === currentPlan(revenues && revenues.size> 0 ? revenues.docs[0].data().usageCharge : null, shopData) &&
                                            <Box paddingBlockStart="200">
                                                <Button disabled>Active plan</Button>
                                            </Box>
                                            }
                                        </Box>
                                        <CardDivider/>
                                        <Box paddingBlockStart="200">  
                                            <Text  variant="bodyMd" as="span" fontWeight="semibold">FEATURES</Text>

                                            <ul style={{listStyle:"none", paddingLeft:0}}>
                                                <li>
                                                    <LegacyStack spacing="extraTight">
                                                            <Icon
                                                                source={CheckCircleIcon}
                                                                tone="success" />
                                                        <Text variant="bodyMd" as="span">Earn up to </Text>
                                                        <Text variant="headingSm" as="h3">$15,000</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Design customization</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">In-depth analytics</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight" wrap={false}>
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Advanced configurator with preview</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Email support</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Smart rule engine</Text>
                                                    </LegacyStack>
                                                </li>
                                        </ul>
                                        </Box>
                                    </Card>
                                    }
                                    { "new" === plan && 
                                     <Card>
                                        <Box minHeight="76px">
                                            <div className="plan-details">
                                                <div className="plan-name">
                                                <Text  variant="bodyMd" as="span" fontWeight="semibold">Scale 3 Plan</Text>
                                                </div>
                                                <LegacyStack spacing="extraTight" alignment="center">
                                                   
                                                    <Text variant="headingMd" as="h2">$499.00</Text>
                                                    <span> /mon</span>
                                                </LegacyStack>
                                            </div>
                                            { "Scale 3 Plan" === currentPlan(revenues && revenues.size> 0 ? revenues.docs[0].data().usageCharge : null, shopData) &&
                                            <Box paddingBlockStart="200">
                                                <Button disabled>Active plan</Button>
                                            </Box>
                                            }
                                        </Box>
                                        <CardDivider/>
                                        <Box paddingBlockStart="200">
                                        <Text  variant="bodyMd" as="span" fontWeight="semibold">FEATURES</Text>

                                            <ul style={{listStyle:"none", paddingLeft:0}}>
                                                <li>
                                                    <LegacyStack spacing="extraTight">
                                                            <Icon
                                                                source={CheckCircleIcon}
                                                                tone="success" />
                                                        <Text variant="bodyMd" as="span">Earn up to </Text>
                                                        <Text variant="headingSm" as="h3">$20,000</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Design customization</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">In-depth analytics</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight" wrap={false}>
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Advanced configurator with preview</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Email support</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Smart rule engine</Text>
                                                    </LegacyStack>
                                                </li>
                                        </ul>
                                        </Box>
                                    </Card>
                                    }
                                    { "new" === plan && 
                                    <Card>
                                        <Box minHeight="76px">
                                            <div className="plan-details">
                                                <div className="plan-name">
                                                <Text  variant="bodyMd" as="span" fontWeight="semibold">Scale 4 Plan</Text>
                                                </div>
                                                <LegacyStack spacing="extraTight" alignment="center">
                                                    
                                                    <Text variant="headingMd" as="h2">$699.00</Text>
                                                    <span> /mon</span>
                                                </LegacyStack>
                                            </div>
                                            { "Scale 4 Plan" === currentPlan(revenues && revenues.size> 0 ? revenues.docs[0].data().usageCharge : null, shopData) &&
                                            <Box paddingBlockStart="200">
                                                <Button disabled>Active plan</Button>
                                            </Box>
                                            }
                                        </Box>
                                        <CardDivider/>
                                        <Box paddingBlockStart="200">
                                        <Text  variant="bodyMd" as="span" fontWeight="semibold">FEATURES</Text>

                                            <ul style={{listStyle:"none", paddingLeft:0}}>
                                                <li>
                                                    <LegacyStack spacing="extraTight">
                                                            <Icon
                                                                source={CheckCircleIcon}
                                                                tone="success" />
                                                        <Text variant="bodyMd" as="span">Earn up to </Text>
                                                        <Text variant="headingSm" as="h3">$30,000</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Design customization</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">In-depth analytics</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight" wrap={false}>
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Advanced configurator with preview</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Email support</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Smart rule engine</Text>
                                                    </LegacyStack>
                                                </li>
                                        </ul>
                                        </Box>
                                    </Card>
                                    }
                                    <Card>
                                        <Box minHeight="76px">
                                            <div className="plan-details">
                                                <div className="plan-name">
                                                <Text  variant="bodyMd" as="span" fontWeight="semibold">Unlimited Plan</Text>
                                                </div>
                                                <LegacyStack spacing="extraTight" alignment="center">
                                                    
                                                    <Text variant="headingMd" as="h2">{"old" === plan ? "$249.99":"old1" === plan? "$279.00":"old2" === plan? "$299.00": "$799.00"}</Text>
                                                    <span> /mon</span>
                                                </LegacyStack>
                                            </div>
                                            { "Unlimited Plan" === currentPlan(revenues && revenues.size> 0 ? revenues.docs[0].data().usageCharge : null, shopData) &&
                                            <Box paddingBlockStart="200">
                                                <Button disabled>Active plan</Button>
                                            </Box>
                                            }
                                        </Box>
                                        <CardDivider/>
                                        <Box paddingBlockStart="200">
                                        <Text  variant="bodyMd" as="span" fontWeight="semibold">FEATURES</Text>

                                            <ul style={{listStyle:"none", paddingLeft:0}}>
                                                <li>
                                                    <LegacyStack spacing="extraTight">
                                                            <Icon
                                                                source={CheckCircleIcon}
                                                                tone="success" />
                                                        <Text variant="bodyMd" as="span">Earn up to </Text>
                                                        <Text variant="headingSm" as="h3">Unlimited</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Design customization</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">In-depth analytics</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight" wrap={false}>
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Advanced configurator with preview</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Email support</Text>
                                                    </LegacyStack>
                                                </li>
                                                <li> 
                                                    <LegacyStack spacing="extraTight">
                                                        <Icon
                                                            source={CheckCircleIcon}
                                                            tone="success" />
                                                        <Text  variant="bodyMd" as="span">Smart rule engine</Text>
                                                    </LegacyStack>
                                                </li>
                                        </ul>
                                        </Box>
                                    </Card>
                                </InlineGrid>
                            </Box>
                    </Layout.Section>
                </Layout>
            </Page>
        );
    
}

export default Billing
